@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900");
/* reclamations panel pickerdate  */
/**
*
* Colors
*
*/
/**
*
* Layout
*
*/
.col-centered {
  float: none;
  margin: 0 auto;
}

.container-fluid {
  padding: 0;
}

.full-height {
  height: 100vh;
}

.full-height-fixed {
  height: calc(100vh - 56px);
  margin-top: 56px;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-right: 0;
  margin-left: 0;
}

.full-height-fixed > * {
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.clickable {
  cursor: pointer;
}

.text-white a {
  color: #fff;
}

.bg-dark {
  background-color: #292b2c !important;
}

.badge-secondary {
  background-color: #563d7c;
}

.sortable {
  cursor: pointer;
}

.st-sort-ascent:before {
  content: '\25B2';
}

.st-sort-descent:before {
  content: '\25BC';
}

.block-ui-container {
  z-index: 100000 !important;
}

.ui-select-choices.ui-select-choices-content.ui-select-dropdown.dropdown-menu {
  display: inline;
}

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

/**
* Scrollbar
 */
*::-webkit-scrollbar {
  width: .5em;
}

*::-webkit-scrollbar-thumb {
  background-color: #292b2c;
  border-radius: 5px;
  outline: 1px solid slategrey;
}

body {
  background-color: #eeeeee;
  font-size: 14px;
}

button,
a {
  cursor: pointer;
  border-radius: 0 !important;
}

button:active, button:focus, button:hover,
a:active,
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
}

.list-group-item.active a {
  color: #fff;
}

textarea,
input {
  border-radius: 0 !important;
}

textarea:active, textarea:focus, textarea:hover,
input:active,
input:focus,
input:hover {
  outline: none;
}

.card,
.input-group-addon {
  border-radius: 0;
}

button:hover, button:active, button:focus {
  box-shadow: none !important;
}

.progress {
  background-color: #5d5e5c;
}

.progress .progress-bar span {
  padding: 0 5px;
}

.input-group-addon {
  background-color: #0275d8;
  color: #fff;
}

.has-danger {
  color: #EB0029;
}

.has-danger .input-group-addon {
  background-color: #EB0029;
  color: #fff;
}

.has-danger input {
  border-color: #EB0029;
}

.has-danger input:focus {
  border-color: #EB0029;
}

.has-danger textarea {
  border-color: #EB0029;
}

.has-danger textarea:focus {
  border-color: #EB0029;
}

th input,
th .select2-container {
  max-width: 150px;
}

.has-danger .select2-choice {
  border: 1px solid #EB0029 !important;
}

.has-danger .select2-choice .select2-arrow {
  background-color: transparent !important;
}

ul.pagination li.pagination-item.active a {
  color: #fff;
}

ul.pagination li.pagination-item.active a:hover {
  color: #fff;
}

.ui-draggable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

.ui-draggable-dragging {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #292b2c;
  color: #fff;
  clear: both;
  float: left;
  width: 150px;
}

.ui-droppable-hover {
  background-color: #0275d8 !important;
  color: #fff;
}

.ui-droppable-hover a {
  color: #fff;
}

.ui-notification {
  box-shadow: none;
  padding: 10px 20px;
  width: 480px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ui-notification h3 {
  font-size: 1.2em;
}

.ui-notification.warning {
  background-color: #f39600;
}

.ui-notification:hover {
  opacity: 1 !important;
}

.ngdialog-content {
  border-radius: 0 !important;
  padding: 25px !important;
  background: #fff !important;
}

.ngdialog-content h3 {
  color: #0275d8;
  margin: 10px 0 20px 0;
}

.ngdialog-content h5 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #0275d8 !important;
}

.ngdialog-content p {
  font-size: 16px;
}

.ngdialog-footer {
  text-align: right;
}

.custom-width .ngdialog-content {
  width: 45% !important;
}

.navbar {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.navbar .navbar-brand .toggleSidebar {
  display: inline-block;
  color: #fff !important;
  margin-right: 10px;
  transition: all ease 500ms;
}

.navbar .navbar-brand .toggleSidebar.toggled {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all ease 500ms;
}

.navbar .navbar-brand img {
  height: 25px;
}

.navbar .dropdown-menu.toggle-right {
  right: 0;
  left: auto;
  top: 45px;
  border: none;
  border-radius: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card {
  border-radius: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
}

.card.fullScreen {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.card .card-header {
  border-radius: 0;
}

.card .card-header .mb-0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.card .card-body {
  padding: 25px;
}

.card .card-block {
  padding: 0;
}

.card .card-block.padding {
  padding: 10px !important;
}

.card .card-block.padding .list-inline {
  margin: 0;
}

.card .card-block .list-group {
  border: none;
}

.card .card-block .list-group .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card .card-block .list-group .list-group-item:first-of-type {
  border-top: none;
}

.ngdialog-content .card,
.sidebar .card {
  box-shadow: none;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #5d5e5c;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eeeeee;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #0275d8;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
}

.custom-radio input:checked ~ .checkmark {
  background-color: #0275d8;
}

.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eeeeee;
  border-radius: 50%;
}

.custom-radio .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  content: "";
  position: absolute;
  display: none;
}

.custom-radio:hover input ~ .checkmark {
  background-color: #5d5e5c;
}

.select2-container {
  margin: 0;
  position: relative;
  display: block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  box-sizing: border-box;
}

.select2-drop {
  box-sizing: border-box;
}

.select2-search {
  box-sizing: border-box;
}

.select2-search input {
  box-sizing: border-box;
}

.select2-container .select2-choice {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-weight: normal;
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 10px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  background: url("../app/assets/images/select2.png") right top no-repeat;
  cursor: pointer;
  outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  width: 100%;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-active {
  border-top: none;
}

.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 4px 4px 0;
}

.select2-container .select2-choice .select2-arrow b {
  display: block;
  width: 100%;
  height: 100%;
  background: url("../app/assets/images/select2.png") no-repeat 0 1px;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
}

.select2-search input {
  width: 100% !important;
  height: auto !important;
  min-width: 100%;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;
  outline: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  background: #fff url("../app/assets/images/select2.png") no-repeat 100% -22px;
  background: url("../app/assets/images/select2.png") no-repeat 100% -22px, linear-gradient(to bottom, #fff 85%, #eee 99%);
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
  width: 100% !important;
}

.select2-search input.select2-active {
  background: #fff url("../app/assets/images/select2-spinner.gif") no-repeat 100%;
  background: url("../app/assets/images/select2-spinner.gif") no-repeat 100%, linear-gradient(to bottom, #fff 85%, #eee 99%);
}

.select2-container-active .select2-choice, .select2-container-active .select2-choices {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  box-shadow: 0 1px 0 #fff inset;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-dropdown-open.select2-drop-above .select2-choice, .select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #0275d8;
  border-top-color: transparent;
  background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to top, #fff 0%, #eee 50%);
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  -webkit-filter: none;
          filter: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}

/* results */
.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label {
  padding-left: 20px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 40px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 60px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 80px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 100px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 110px;
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 120px;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}

.select2-results .select2-result-label {
  font-weight: normal;
  font-size: 14px;
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-results .select2-highlighted {
  background: #0275d8;
  color: #fff;
}

.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: transparent;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}

.select2-results .select2-no-results, .select2-results .select2-searching, .select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
}

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url("../app/assets/images/select2-spinner.gif") no-repeat 100%;
}

/* disabled styles */
.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}

/* multiselect */
.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #ccc;
  cursor: text;
  overflow: hidden;
  background-color: #fff;
  background-image: linear-gradient(to bottom, #eee 1%, #fff 15%);
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #0275d8;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  box-shadow: none;
  background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url("../app/assets/images/select2-spinner.gif") no-repeat 100% !important;
}

.select2-default {
  color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 18px;
  margin: 3px 0 3px 5px;
  position: relative;
  line-height: 13px;
  color: #333;
  cursor: default;
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #e4e4e4;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4;
}

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 4px;
  font-size: 1px;
  outline: none;
  background: url("../app/assets/images/select2.png") right top no-repeat;
}

.select2-container-multi .select2-search-choice-close {
  left: 3px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover, .select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px;
}

.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: none;
}

/* disabled styles */
/* end multiselect */
.select2-result-selectable .select2-match, .select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

/* Retina-ize icons */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .select2-search input, .select2-search-choice-close {
    background-image: url("../app/assets/images/select2x2.png") !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
  }
  .select2-container .select2-choice abbr, .select2-container .select2-choice .select2-arrow b {
    background-image: url("../app/assets/images/select2x2.png") !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
  }
  .select2-search input {
    background-position: 100% -21px !important;
  }
}

.select2-chosen button {
  background-color: transparent !important;
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  vertical-align: middle;
}

.switch input {
  display: none;
}

.switch-text {
  line-height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.btn-danger,
.btn-success,
.btn-info,
.btn-primary {
  color: #fff !important;
}

.btn-comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: #fff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #0275d8;
}

.page-heading .dropdown-menu,
.admin-heading .dropdown-menu {
  right: 0;
  left: auto;
  border: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 0;
}

.actions-dropdown button {
  width: 237px;
}

.ui-calendar .controls h5 {
  display: inline-block;
  min-width: 150px;
  text-align: center;
  text-transform: capitalize;
}

.ui-calendar .controls .btn-default {
  background-color: transparent;
  color: #aaa;
}

.ui-calendar table.day-view thead tr th,
.ui-calendar table.month-view thead tr th,
.ui-calendar table.week-view thead tr th {
  text-transform: capitalize;
  text-align: center;
  vertical-align: middle;
  background-color: #0275d8;
  color: #fff;
}

.ui-calendar table.day-view thead tr th h5,
.ui-calendar table.month-view thead tr th h5,
.ui-calendar table.week-view thead tr th h5 {
  margin: 0;
}

.ui-calendar table.day-view thead tr th .btn,
.ui-calendar table.month-view thead tr th .btn,
.ui-calendar table.week-view thead tr th .btn {
  background-color: transparent;
  font-size: 1.5em;
  color: #fff;
}

.ui-calendar table.month-view tbody tr td {
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.2em;
  height: 100px;
  width: calc(100%/7);
}

.ui-calendar table.month-view tbody tr td.current-month {
  color: rgba(0, 0, 0, 0.7);
}

.ui-calendar table.month-view tbody tr td.today {
  color: #0275d8;
}

.ui-calendar table.month-view tbody tr td.hasEvent {
  padding-left: 0;
  padding-right: 0;
}

.ui-calendar table.month-view tbody tr td.hasEvent.isEventStart span.evt {
  position: relative;
  border-left: 2px solid #2c1b38;
}

.ui-calendar table.month-view tbody tr td.hasEvent.isEventStart span.evt small {
  display: block;
  position: absolute;
  white-space: nowrap;
  color: #fff;
  left: 10px;
  top: 0;
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.ui-calendar table.month-view tbody tr td.hasEvent.isEventEnd span.evt {
  border-right: 2px solid #2c1b38;
}

.ui-calendar table.month-view tbody tr td.hasEvent span.evt {
  display: block;
  width: 100%;
  height: 25px;
  background-color: #613d7c;
  margin-top: 10px;
}

.ui-calendar table.day-view thead tr th {
  text-align: center;
}

.ui-calendar table.day-view tbody tr th {
  text-align: center;
  width: calc(100%/8);
}

.ui-calendar table.day-view tbody tr td {
  text-align: center;
  vertical-align: top;
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
}

.ui-calendar table.day-view tbody tr td.has-event {
  border: none;
}

.ui-calendar table.day-view tbody tr td.has-event span.evt {
  position: relative;
  display: block;
  height: 100%;
  width: 50px;
  background-color: #613d7c;
  margin: auto;
}

.ui-calendar table.day-view tbody tr td.has-event span.evt small {
  font-size: 1em;
  display: block;
  position: absolute;
  white-space: nowrap;
  color: #fff;
  left: 35px;
  top: 10px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 99;
}

.ui-calendar table.day-view tbody tr td.isEventStart span.evt {
  border-top: 2px solid #2c1b38;
}

.ui-calendar table.day-view tbody tr td.isEventEnd span.evt {
  border-bottom: 2px solid #2c1b38;
}

.ui-calendar table.week-view tbody tr th {
  text-align: center;
}

.ui-calendar table.week-view tbody tr td {
  text-align: center;
  vertical-align: top;
  width: calc(100%/8);
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
}

.ui-calendar table.week-view tbody tr td.has-event {
  border: none;
}

.ui-calendar table.week-view tbody tr td.has-event span.evt {
  position: relative;
  display: block;
  height: 100%;
  width: 25px;
  background-color: #613d7c;
  margin: auto;
}

.ui-calendar table.week-view tbody tr td.has-event span.evt small {
  display: block;
  position: absolute;
  white-space: nowrap;
  color: #fff;
  left: 23px;
  top: 10px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 99;
}

.editable {
  color: #0275d8 !important;
  font-size: 14px;
  white-space: nowrap;
}

.badge-info .editable {
  color: #fff !important;
}

.editable-buttons button {
  background-color: #0275d8;
  border: none;
  color: #fff;
  padding: 5px;
  font-size: 14px;
}

.editable-buttons button:last-of-type {
  background-color: #EB0029;
}

.editable-controls select {
  padding: 3px;
  border: none;
}

.fc-content {
  color: #fff;
}

.fc-center h2 {
  text-transform: capitalize;
}

.fc-day-header {
  background-color: #0275d8;
  color: #fff;
  text-transform: capitalize;
}

.fc-button {
  border: 1px solid #0275d8;
  text-shadow: none;
  background-color: #0275d8;
  color: #fff;
  font-size: 1em;
}

.fc-button.fc-state-default {
  background-image: none;
  background-color: #0275d8;
  color: #fff;
}

.fc-button.fc-state-active {
  background-color: #025aa5;
  color: #fff;
}

.fc-button.fc-state-hover {
  background-color: #0267bf;
  color: #fff;
}

.udatetime {
  position: relative;
  padding: 0 !important;
}

.udatetime table.cal {
  position: absolute;
  z-index: 9999;
}

.udatetime td.day button {
  cursor: pointer;
}

.udatetime td.day button.btn-default {
  background-color: transparent;
}

.udatetime table.table.cal {
  display: none !important;
  transition: all ease 2s;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  width: auto !important;
}

.udatetime table.cal.shown {
  display: table !important;
  transition: all ease 2s;
}

.udatetime .input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.udatetime .input-group .form-control:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-addon:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn, .udatetime .input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group-btn:not(:last-child) > .btn, .udatetime .input-group-btn:not(:last-child) > .btn-group > .btn, .udatetime .input-group-btn:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.udatetime .input-group .form-control, .udatetime .input-group-addon, .udatetime .input-group-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.udatetime .input-group .form-control {
  position: relative;
  z-index: 2;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.udatetime .form-control {
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin: 0 !important;
}

.udatetime .form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.udatetime .input-group .form-control:not(:first-child), .udatetime .input-group-addon:not(:first-child) {
  margin-left: -17px;
  width: 36px;
  height: 36px;
  padding: 0;
  text-align: center;
  z-index: 2;
}

.udatetime .input-group-btn:not(:first-child) > .btn, .udatetime .input-group-btn:not(:first-child) > .btn-group > .btn, .udatetime .input-group-btn:not(:first-child) > .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.udatetime .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn, .udatetime .input-group-btn:not(:last-child) > .btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.udatetime .input-group-addon {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #fafafa;
  text-align: center;
  background-color: #0275d8;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  white-space: nowrap;
  vertical-align: middle;
}

.udatetime .input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.udatetime .input-group .form-control, .udatetime .input-group-addon, .udatetime .input-group-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.udatetime fieldset.form-group {
  margin-bottom: 1rem;
}

.udatetime .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.udatetime .table thead th:first-of-type {
  width: auto !important;
}

.udatetime .table thead th:last-of-type {
  width: auto !important;
}

.udatetime .table thead th:last-child {
  min-width: auto;
}

.udatetime .table tr:hover {
  background-color: transparent;
}

.udatetime .table tr td {
  border: none !important;
  text-align: center;
  width: auto !important;
}

.udatetime .table tr td .btn {
  height: 30px;
  width: 30px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center !important;
  display: inline-block !important;
  margin: auto;
  padding: 0 !important;
  line-height: inherit;
  border-radius: 50% !important;
}

.udatetime .table tr td .btn.month {
  height: 50px;
  width: 50px;
  text-transform: capitalize;
}

.udatetime .table tr td .btn.btn-default {
  border: none;
}

.udatetime .table tr td .btn.btn-block {
  width: 100%;
  padding: 10px;
}

.udatetime .table tfoot td .btn {
  border-radius: 0;
  height: auto;
  width: auto;
}

.udatetime .table tfoot td .btn.btn-info {
  background-color: transparent;
  color: #025aa5 !important;
  border: none;
  font-size: 1.2em;
}

.udatetime table {
  border-collapse: collapse;
  background-color: transparent;
}

.udatetime .table thead th {
  vertical-align: bottom;
  text-transform: capitalize;
  border: none;
}

.udatetime .table thead th button {
  background-color: transparent;
  color: #eeeeee;
  font-size: 1.5em;
}

.udatetime thead tr:first-of-type th {
  background-color: #025aa5;
}

.udatetime thead tr:last-of-type {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.udatetime .thead-inverse th {
  color: #fff;
  background-color: #0275d8;
}

.udatetime .table td, .udatetime .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #eeeeee;
}

.udatetime .text-center {
  text-align: center !important;
}

.udatetime .btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .5rem 1rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: all .2s ease-in-out;
}

.udatetime .btn-group-sm > .btn, .udatetime .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.udatetime button, .udatetime input, .udatetime select, .udatetime textarea {
  line-height: inherit;
}

.udatetime [role=button], .udatetime a, .udatetime area, .udatetime button, .udatetime input, .udatetime label, .udatetime select, .udatetime summary, .udatetime textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.udatetime [type=reset], .udatetime [type=submit], .udatetime button, .udatetime html [type=button] {
  -webkit-appearance: button;
}

.udatetime button, .udatetime input {
  overflow: visible;
}

.udatetime button, .udatetime select {
  text-transform: none;
}

.udatetime .btn-block {
  display: block;
  width: 100%;
}

.udatetime .btn-info {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.udatetime .btn-success {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.udatetime .btn-primary {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.udatetime .h3, .udatetime h3 {
  font-size: 1.75rem;
}

.udatetime .h1, .udatetime .h2, .udatetime .h3, .udatetime .h4, .udatetime .h5, .udatetime .h6, .udatetime h1, .udatetime h2, .udatetime h3, .udatetime h4, .udatetime h5, .udatetime h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.udatetime input:-moz-read-only {
  background-color: #ccc;
  cursor: not-allowed;
}

.udatetime input:read-only {
  background-color: #ccc;
  cursor: not-allowed;
}

.udatetime input:-moz-read-only + span.input-group-addon {
  background-color: #ccc;
}

.udatetime input:read-only + span.input-group-addon {
  background-color: #ccc;
}

.udatetime input.form-control {
  width: auto !important;
  max-width: 160px;
  margin-left: 10px;
}

.udatetime input.time-input {
  font-size: 1.8em;
  border: none;
  width: 80px !important;
  text-align: center;
}

.udatetime input {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.comments {
  margin-bottom: 20px;
}

.comment {
  position: relative;
}

.comment .card-body {
  padding: 10px 15px;
}

.comment:before {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  left: -10px;
  height: 20px;
  width: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background: #fff;
}

.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #307bbb;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: #cccccc;
}

.c100 *,
.c100 *:before,
.c100 *:after {
  box-sizing: content-box;
}

.c100.center {
  float: none;
  margin: 0 auto;
}

.c100.big {
  font-size: 240px;
}

.c100.small {
  font-size: 80px;
}

.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #f5f5f5;
  width: 0.84em;
  height: 0.84em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}

.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}

.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}

.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}

.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}

.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}

.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}

.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}

.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}

.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}

.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}

.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}

.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}

.c100.p13 .bar {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}

.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}

.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}

.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}

.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}

.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}

.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}

.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}

.c100.p21 .bar {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}

.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}

.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}

.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}

.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.c100.p26 .bar {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}

.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}

.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}

.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}

.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}

.c100.p31 .bar {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}

.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}

.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}

.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}

.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}

.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}

.c100.p37 .bar {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}

.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}

.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}

.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}

.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}

.c100.p42 .bar {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}

.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}

.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}

.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}

.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}

.c100.p47 .bar {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}

.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}

.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}

.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}

.c100.p52 .bar {
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}

.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}

.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}

.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}

.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}

.c100.p57 .bar {
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}

.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}

.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}

.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}

.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}

.c100.p62 .bar {
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}

.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}

.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}

.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}

.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}

.c100.p67 .bar {
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}

.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}

.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}

.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}

.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}

.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}

.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}

.c100.p74 .bar {
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}

.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}

.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}

.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}

.c100.p79 .bar {
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}

.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}

.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}

.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}

.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}

.c100.p84 .bar {
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}

.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}

.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}

.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}

.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}

.c100.p89 .bar {
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}

.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}

.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}

.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}

.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}

.c100.p94 .bar {
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}

.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}

.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}

.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}

.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}

.c100.p99 .bar {
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}

.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.c100:hover {
  cursor: default;
}

.c100:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: #307bbb;
}

.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}

.c100.dark {
  background-color: #777777;
}

.c100.dark .bar,
.c100.dark .fill {
  border-color: #c6ff00 !important;
}

.c100.dark > span {
  color: #777777;
}

.c100.dark:after {
  background-color: #666666;
}

.c100.dark:hover > span {
  color: #c6ff00;
}

.c100.green .bar,
.c100.green .fill {
  border-color: #4db53c !important;
}

.c100.green:hover > span {
  color: #4db53c;
}

.c100.green.dark .bar,
.c100.green.dark .fill {
  border-color: #5fd400 !important;
}

.c100.green.dark:hover > span {
  color: #5fd400;
}

.c100.orange .bar,
.c100.orange .fill {
  border-color: #dd9d22 !important;
}

.c100.orange:hover > span {
  color: #dd9d22;
}

.c100.orange.dark .bar,
.c100.orange.dark .fill {
  border-color: #e08833 !important;
}

.c100.orange.dark:hover > span {
  color: #e08833;
}

.spinner-transparent > .ngdialog-content {
  background: transparent !important;
}

.comments {
  margin-bottom: 20px;
}

.comment {
  position: relative;
}

.comment .card-body {
  padding: 10px 15px;
}

.comment:before {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  left: -10px;
  height: 20px;
  width: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background: #fff;
}

#map {
  width: 100%;
  height: 100%;
  min-height: 250px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #EB0029;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #b80020;
}

.leaflet-tooltip {
  border-radius: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-weight: bold;
}

.leaflet-tooltip .tooltip-name {
  color: #0275d8;
  font-size: 1.2em;
}

/**
*
* Stimulsoft styling
*
*/
table.stiJsViewerToolBarTable {
  border: none;
  padding: 10px 25px;
  background: #0275d8;
  color: white;
  font-family: "Helvetica neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  width: 100%;
}

.stiJsViewerPageShadow {
  border: none !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 -2px 2px rgba(0, 0, 0, 0.1);
}

.stiJsViewerStandartSmallButtonOver {
  background-color: #108ffd;
}

.stiJsViewerToolTip {
  border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.stiJsViewerToolTip .stiJsViewerToolTipTextCell {
  border-bottom: 1px solid #f1f1f1;
}

.stiJsViewerDropdownMenu, .stiJsViewerDropdownPanel, .stiJsViewerMenu {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border: none;
  font-size: 14px;
}

.stiJsViewerMenuStandartItemOver {
  background-color: #f1f1f1;
}

.stiJsViewerToolBarSeparator {
  background-color: #fff;
  margin: 0 5px;
}

.stiJsViewerVerticalMenuSeparator {
  background-color: #f1f1f1;
}

.stiJsViewerStandartSmallButton {
  padding: 5px;
}

.stiJsViewerStandartSmallButtonOver,
.stiJsViewerStandartSmallButtonSelected {
  color: #fff;
  background-color: #0083cc;
}

.stiJsViewerTextBox {
  background-color: #fff;
  padding: 5px;
  border: none;
  margin: 0 5px;
}

.stiJsViewerTextBoxDisabled {
  background-color: #f1f1f1;
  color: #5d5e5c;
}

.stiJsViewerToolBarTable {
  border: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

table.stiJsViewerToolBarTable > tbody > tr.stiJsViewerClearAllStyles > td:last-of-type {
  display: none;
}

.panel {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  margin: 10px 10px;
}

.panel ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.panel ul li {
  margin: 0 2px;
}

.panel ul li.label {
  line-height: 34px;
}

.panel ul.datetime {
  padding: 10px 0;
  position: relative;
}

.panel ul.datetime .datetime-picker-dropdown {
  color: #5d5e5c;
  left: 0;
  display: block;
  padding: 10px;
  position: absolute;
  background: #fff;
  border-radius: 3px;
  list-style: none;
}

.panel ul.datetime .datetime-picker-dropdown td button, .panel ul.datetime .datetime-picker-dropdown th button {
  color: #5d5e5c;
  margin: 0;
  padding: 2px;
}

.panel ul.datetime .datetime-picker-dropdown button {
  padding: 10px !important;
  border-radius: 5px;
}

.panel ul.datetime .datetime-picker-dropdown button.btn-default {
  background-color: #fff;
}

.panel ul.datetime .datetime-picker-dropdown button.btn-default:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.panel ul.datetime .datetime-picker-dropdown button.btn-primary {
  background-color: #0275d8;
}

.panel ul.datetime .datetime-picker-dropdown button.btn {
  padding: 10px 25px;
  margin: 1px;
}

.panel ul.datetime .datetime-picker-dropdown button.btn.btn-primary {
  background-color: #0275d8;
  color: #fff;
}

.panel ul.datetime li {
  padding: 10px;
  color: #5d5e5c;
}

.panel ul.datetime li input {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  width: 140px;
  height: 37px;
}

.panel ul.datetime li button {
  background-color: #0275d8;
  color: #fff;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  margin-left: -5px;
  height: 37px;
}

.panel ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px 10px 5px 10px;
  margin: 0 10px;
  color: #5d5e5c;
}

.panel ul li a span.icon {
  width: 40px;
  height: 40px;
  display: block;
  background-color: #5d5e5c;
  border-radius: 50%;
  margin-bottom: 5px;
}

.panel ul li a span.icon i {
  display: block;
  width: 24px;
  height: 28px;
  margin: 5px auto auto;
}

.panel ul li a span.icon i.calendar {
  background: url("../app/assets/images//device/calendar.png") no-repeat;
}

.panel ul li a span.icon i.day {
  background: url("../app/assets/images//device/calendar-day.png") no-repeat;
}

.panel ul li a span.icon i.week {
  background: url("../app/assets/images//device/calendar-week.png") no-repeat;
}

.panel ul li a span.icon i.month {
  background: url("../app/assets/images//device/calendar-month.png") no-repeat;
}

.panel ul li.active a {
  color: #0275d8;
  border-bottom: 5px solid #0275d8;
}

.panel ul li.active a span.icon {
  background-color: #0275d8;
}

.login-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.login-page img.logo {
  width: 30%;
  margin-bottom: 20px;
}

.login-page .centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  padding: 0;
  border-radius: 5px;
}

.login-page .shadow {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 1;
}

.login-page .background {
  position: relative;
  height: 100vh;
}

.login-page .background:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: url("../app/assets/images/background.jpg") no-repeat center;
  background-size: cover;
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.login-page .form-container {
  width: 100%;
  padding: 10px 25px;
  background-color: transparent;
  border: none;
}

.login-page .form-container .languages {
  display: inline-block;
  margin-right: auto;
}

.login-page .form-container .sign-in {
  margin-right: auto;
  float: right;
}

.login-page .lang {
  opacity: .5;
}

.login-page .lang.active {
  opacity: 1;
}

.login-page .footer {
  background-color: #fff;
  padding: 25px;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: justify;
      align-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  z-index: 2;
}

.login-page .footer .logos {
  padding-left: 25px;
}

.login-page .footer .logos .logo {
  height: 65px;
  width: auto;
  margin: 0 10px;
}

.login-page .footer .socials {
  padding-right: 25px;
}

.login-page .footer .socials li {
  padding: 0 10px;
}

.login-page .footer .socials li a img {
  height: 30px;
  width: auto;
}

.sidebar {
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 999;
}

.sidebar li.list-group-item.disabled {
  opacity: .7;
}

.sidebar li.list-group-item.disabled.active {
  opacity: 1;
}

.widget .card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.home {
  padding: 25px;
}

.home .list-group-item.child {
  padding-left: 3rem;
}

.home .list-group-item i.tree-icon {
  padding-right: 10px;
  width: 26px;
}

.home .page-heading .btn {
  height: 40px;
}

.thumb {
  width: 24px;
  height: 24px;
  float: none;
  position: relative;
  top: 7px;
}

form .progress {
  line-height: 15px;
}

.progress {
  display: inline-block;
  width: 100px;
}

.progress div {
  font-size: smaller;
  background: #5cb85c;
  width: 0;
}

.installation-request .ui-select-container .select2-chosen {
  position: relative;
  margin-right: 5px;
}

.installation-request .ui-select-container .select2-chosen button {
  position: absolute;
  top: -3px;
  right: 0;
}

.print table, .print th, .print td {
  border: 1px solid black;
}

.flat-dialog {
  width: 100%;
}

.flat-dialog .ngdialog-content {
  width: 884px !important;
}

.bk-btn {
  height: 32px;
  width: 32px;
  background-color: black;
  border-radius: 50%;
  margin-left: -1%;
  margin-top: -1%;
  margin-bottom: 1%;
}

.bk-btn .bk-btn-triangle {
  position: relative;
  top: 8px;
  left: 6.4px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
}

.bk-btn .bk-btn-bar {
  position: relative;
  background-color: white;
  height: 4.8px;
  width: 6.72px;
  top: -2.24px;
  left: 14.08px;
}

.admin {
  padding: 25px;
}

.admin .admin-heading .btn {
  height: 40px;
}

ol.onglet {
  list-style: none;
  padding-top: 10px;
  padding-left: 0;
  margin: 0 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

ol.onglet button {
  padding: 10px;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
}

ol.onglet li {
  display: inline-block;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .navbar .navbar-brand .toggleSidebar {
    display: none;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar .navbar-brand .toggleSidebar {
    display: none;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-brand .toggleSidebar {
    display: inline-block;
  }
  .login-page .background {
    display: none;
  }
  .sidebar {
    position: fixed;
    width: 50%;
    left: -100%;
    top: 54px;
    bottom: 0;
    transition: all ease 500ms;
  }
  .sidebar.shown {
    left: 0;
    transition: all ease 500ms;
  }
  .installation-request .ui-select-container {
    width: 120px;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .navbar .navbar-brand .toggleSidebar {
    display: inline-block;
  }
  .login-page .background {
    display: none;
  }
  .sidebar {
    position: fixed;
    width: 80%;
    left: -100%;
    top: 54px;
    bottom: 0;
    transition: all ease 500ms;
  }
  .sidebar.shown {
    left: 0;
    transition: all ease 500ms;
  }
  .installation-request .ui-select-container {
    width: 120px;
  }
}

/* Portrait phones and smaller */
/**
 * CONTENTS
 *
 * #Introduction........Naming conventions used throughout the code.
 *
 * #SETTINGS
 * Variables............Globally-available variables and config.
 *
 * #TOOLS
 * Mixins...............Useful mixins.
 *
 * #GENERIC
 * Demo styles..........Styles for demo only (consider removing these).
 *
 * #BASE
 * Raw styles...........The very basic component wrapper.
 * Modifiers............The basic styles dependant on component placement.
 * Debuggers............The basic styles dependant on component placement.
 *
 * #BUTTONS
 * Base..................Wrapping and constraining every button.
 * Modifiers.............Styles that depends on state and settings.
 * Animations............Main animations of the component.
 * Debuggers.............Styles for development.
 *
 * #LABELS
 * Base..................Wrapping and constraining every label.
 * Modifiers.............Styles that depends on state and settings.
 * Debuggers.............Styles for development.
 *
 * #DEVELOPMENT
 * In development........These styles are in development and not yet finalised
 * Debuggers.............Helper styles and flags for development.
 */
/*------------------------------------*    #Introduction
\*------------------------------------*/
/**
 * The code AND the comments use naming conventions to refer to each part of
 * the UI put in place by this component. If you see that somewhere they are
 * not followed please consider a Pull Request. The naming conventions are:
 *
 * "Component" : the widget itself as a whole. This is the last time it will be
 *               called anything different than "component". So, stay away from
 *               "widget", "button" or anything else when referring to the
 *               Component in general.
 *
 * "Main Button" : the button that is always in view. Hovering or clicking on it
 *                 will reveal the child buttons.
 *
 * "Child buttons" : if you've read the previous point you know what they are.
 *                   Did you read the previous point? :)
 *
 * "Label(s)" : the tooltip that fades in when hovering over a button.

/*------------------------------------*    #SETTINGS | Variables
\*------------------------------------*/
/**
 * These variables are the default styles that serve as fallback and can be
 * easily customised at compile time.
 * Consider overriding them in your own style sheets rather than editing them
 * here. Refer to the docs for more info.
 */
/* COLORS ----------------------------*/
/* EFFECTS ---------------------------*/
/* SPEEDS ----------------------------*/
/* SIZES -----------------------------*/
/* SPACING ---------------------------*/
/* OTHER VARIABLES -------------------*/
/*------------------------------------*    #BASE | Raw styles
\*------------------------------------*/
/**
 * The very core styling of the button.
 * These styles are shared by every instance of the button.
 * Styles placed here should NOT care about placement in the screen,
 * options chosen by the user or state of the button.
 */
.mfb-component--tl, .mfb-component--tr, .mfb-component--bl, .mfb-component--br {
  box-sizing: border-box;
  margin: 25px;
  position: fixed;
  white-space: nowrap;
  z-index: 30;
  padding-left: 0;
  list-style: none;
}

.mfb-component--tl *, .mfb-component--tr *, .mfb-component--bl *, .mfb-component--br *, .mfb-component--tl *:before, .mfb-component--tr *:before, .mfb-component--bl *:before, .mfb-component--br *:before, .mfb-component--tl *:after, .mfb-component--tr *:after, .mfb-component--bl *:after, .mfb-component--br *:after {
  box-sizing: inherit;
}

/*------------------------------------*    #BASE | Modifiers
\*------------------------------------*/
/**
 * These styles depends on the placement of the button.
 * Styles can be:
 * 1. Top-left:  modified by the " --tl " suffix.
 * 2. Top-right: modified by the " --tr " suffix.
 * 3. Bottom-left:  modified by the " --bl " suffix.
 * 4. Bottom-right: modified by the " --br " suffix.
 */
.mfb-component--tl {
  left: 0;
  top: 0;
}

.mfb-component--tr {
  right: 0;
  top: 0;
}

.mfb-component--bl {
  left: 0;
  bottom: 0;
}

.mfb-component--br {
  right: 0;
  bottom: 0;
}

/*------------------------------------*    #BUTTONS | Base
\*------------------------------------*/
.mfb-component__button--main, .mfb-component__button--child {
  background-color: #0a65ef;
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 50% !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-user-drag: none;
  color: #f1f1f1;
}

/**
 * This is the unordered list for the list items that contain
 * the child buttons.
 *
 */
.mfb-component__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mfb-component__list > li {
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  padding: 10px 0;
  margin: -10px 0;
}

/**
 * These are the basic styles for all the icons inside the main button
 */
.mfb-component__icon, .mfb-component__main-icon--active,
.mfb-component__main-icon--resting, .mfb-component__child-icon {
  position: absolute;
  font-size: 18px;
  text-align: center;
  line-height: 56px;
  width: 100%;
}

.mfb-component__wrap {
  padding: 25px;
  margin: -25px;
}

[data-mfb-toggle="hover"]:hover .mfb-component__icon, [data-mfb-toggle="hover"]:hover .mfb-component__main-icon--active, [data-mfb-toggle="hover"]:hover
.mfb-component__main-icon--resting, [data-mfb-toggle="hover"]:hover .mfb-component__child-icon,
[data-mfb-state="open"] .mfb-component__icon,
[data-mfb-state="open"] .mfb-component__main-icon--active,
[data-mfb-state="open"]
.mfb-component__main-icon--resting,
[data-mfb-state="open"] .mfb-component__child-icon {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

/*------------------------------------*    #BUTTONS | Modifiers
\*------------------------------------*/
.mfb-component__button--main {
  height: 56px;
  width: 56px;
  z-index: 20;
}

.mfb-component__button--child {
  height: 56px;
  width: 56px;
}

.mfb-component__main-icon--active,
.mfb-component__main-icon--resting {
  -webkit-transform: scale(1) rotate(360deg);
  transform: scale(1) rotate(360deg);
  transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 1, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 1, 1), -webkit-transform 150ms cubic-bezier(0.4, 0, 1, 1);
}

.mfb-component__child-icon,
.mfb-component__child-icon {
  line-height: 56px;
  font-size: 18px;
}

.mfb-component__main-icon--active {
  opacity: 0;
}

[data-mfb-toggle="hover"]:hover .mfb-component__main-icon,
[data-mfb-state="open"] .mfb-component__main-icon {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--resting,
[data-mfb-state="open"] .mfb-component__main-icon--resting {
  opacity: 0;
  position: absolute !important;
}

[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--active,
[data-mfb-state="open"] .mfb-component__main-icon--active {
  opacity: 1;
}

/*------------------------------------*    #BUTTONS | Animations
\*------------------------------------*/
/**
 * SLIDE IN + FADE
 * When hovering the main button, the child buttons slide out from beneath
 * the main button while transitioning from transparent to opaque.
 *
 */
.mfb-component--tl.mfb-slidein .mfb-component__list li,
.mfb-component--tr.mfb-slidein .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li, .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px);
  transform: translateY(140px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px);
  transform: translateY(210px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px);
  transform: translateY(280px);
}

.mfb-component--bl.mfb-slidein .mfb-component__list li,
.mfb-component--br.mfb-slidein .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li, .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px);
  transform: translateY(-70px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px);
  transform: translateY(-210px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px);
  transform: translateY(-280px);
}

/**
 * SLIDE IN SPRING
 * Same as slide-in but with a springy animation.
 *
 */
.mfb-component--tl.mfb-slidein-spring .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-slidein-spring .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li, .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
  -webkit-transform: translateY(140px);
  transform: translateY(140px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
  -webkit-transform: translateY(210px);
  transform: translateY(210px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
  -webkit-transform: translateY(280px);
  transform: translateY(280px);
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li, .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  transition-delay: 0.05s;
  -webkit-transform: translateY(-70px);
  transform: translateY(-70px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  transition-delay: 0.1s;
  -webkit-transform: translateY(-140px);
  transform: translateY(-140px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  transition-delay: 0.15s;
  -webkit-transform: translateY(-210px);
  transform: translateY(-210px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  transition-delay: 0.2s;
  -webkit-transform: translateY(-280px);
  transform: translateY(-280px);
}

/**
 * ZOOM-IN
 * When hovering the main button, the child buttons grow
 * from zero to normal size.
 *
 */
.mfb-component--tl.mfb-zoomin .mfb-component__list li,
.mfb-component--tr.mfb-zoomin .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(0);
  transform: translateY(70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(0);
  transform: translateY(140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(0);
  transform: translateY(210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(0);
  transform: translateY(280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(1);
  transform: translateY(70px) scale(1);
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(1);
  transform: translateY(140px) scale(1);
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(1);
  transform: translateY(210px) scale(1);
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(1);
  transform: translateY(280px) scale(1);
  transition-delay: 0.2s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li,
.mfb-component--br.mfb-zoomin .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(0);
  transform: translateY(-70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(0);
  transform: translateY(-140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(0);
  transform: translateY(-210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(0);
  transform: translateY(-280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(1);
  transform: translateY(-70px) scale(1);
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(1);
  transform: translateY(-140px) scale(1);
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(1);
  transform: translateY(-210px) scale(1);
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(1);
  transform: translateY(-280px) scale(1);
  transition-delay: 0.2s;
}

/**
 * FOUNTAIN
 * When hovering the main button the child buttons
 * jump into view from outside the viewport
 */
.mfb-component--tl.mfb-fountain .mfb-component__list li,
.mfb-component--tr.mfb-fountain .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(0);
  transform: translateY(-70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(0);
  transform: translateY(-140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(0);
  transform: translateY(-210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(0);
  transform: translateY(-280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(1);
  transform: translateY(70px) scale(1);
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(1);
  transform: translateY(140px) scale(1);
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(1);
  transform: translateY(210px) scale(1);
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(1);
  transform: translateY(280px) scale(1);
  transition-delay: 0.2s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li,
.mfb-component--br.mfb-fountain .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(70px) scale(0);
  transform: translateY(70px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(140px) scale(0);
  transform: translateY(140px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(210px) scale(0);
  transform: translateY(210px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(280px) scale(0);
  transform: translateY(280px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1), .mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-70px) scale(1);
  transform: translateY(-70px) scale(1);
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2), .mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-140px) scale(1);
  transform: translateY(-140px) scale(1);
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3), .mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-210px) scale(1);
  transform: translateY(-210px) scale(1);
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4), .mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-280px) scale(1);
  transform: translateY(-280px) scale(1);
  transition-delay: 0.2s;
}

/*------------------------------------*    #LABELS | base
\*------------------------------------*/
/**
 * These are the labels associated to each button,
 * exposed only when hovering the related button.
 * They are called labels but are in fact data-attributes of
 * each button (an anchor tag).
 */
[data-mfb-label]:after {
  content: attr(data-mfb-label);
  opacity: 0;
  transition: all 0.5s;
  background: rgba(0, 0, 0, 0.9);
  padding: 4px 10px;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-weight: normal;
  pointer-events: none;
  line-height: normal;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  transition: all 0.5s;
}

[data-mfb-toggle="hover"] [data-mfb-label]:hover:after,
[data-mfb-state="open"] [data-mfb-label]:after {
  content: attr(data-mfb-label);
  opacity: 1;
  transition: all 0.3s;
}

/*------------------------------------*    #LABELS | Modifiers
\*------------------------------------*/
.mfb-component--br [data-mfb-label]:after, .mfb-component--tr [data-mfb-label]:after {
  content: attr(data-mfb-label);
  right: 70px;
}

.mfb-component--br .mfb-component__list [data-mfb-label]:after, .mfb-component--tr .mfb-component__list [data-mfb-label]:after {
  content: attr(data-mfb-label);
  right: 70px;
}

.mfb-component--tl [data-mfb-label]:after, .mfb-component--bl [data-mfb-label]:after {
  content: attr(data-mfb-label);
  left: 70px;
}

.mfb-component--tl .mfb-component__list [data-mfb-label]:after, .mfb-component--bl .mfb-component__list [data-mfb-label]:after {
  content: attr(data-mfb-label);
  left: 70px;
}

/*------------------------------------*    #DEVELOPMENT | In development
\*------------------------------------*/
/**
 * This part is where unfinished code should stay.
 * When a feature is ready(sh) move these styles to their proper place.
 */
/*------------------------------------*    #DEVELOPMENT | Debuggers
\*------------------------------------*/
/**
 * These are mainly helpers for development. They do not have to end up
 * in production but it's handy to keep them when developing.
 */
/**
 * Apply this class to the html tag when developing the slide-in button
 */
